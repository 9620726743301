import React from "react";
import { map, join, find } from "lodash";
import classNames from "classnames";
import moment from "moment";
import { useNavigate } from "helpers/tixxt-router";
import { SimpleMembership } from "../../@types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

type MessageRowProps = {
  id: string;
  subject: string;
  members: SimpleMembership[];
  date: string;
  content: string;
  selectMessage: (messageId: string) => void;
  isActive: boolean;
  read: boolean;
  hasAttachments: boolean;
  hasReplies: boolean;
  conversationLength: number | undefined;
  to_count: number | null;
};

function MessageRow({
  id,
  subject,
  members,
  date,
  content,
  selectMessage,
  isActive,
  read,
  hasReplies,
  hasAttachments,
  conversationLength,
  to_count,
}: MessageRowProps) {
  const member = members?.[0];
  const navigate = useNavigate();

  return (
    <div
      className={classNames("messageRow table-row", {
        activeMessageRow: isActive,
        "bg-primary/10": isActive,
        "hover:bg-primary/5": !isActive,
        unreadMessage: !read,
      })}
    >
      <div
        className={
          "table-cell py-3 pl-3 pr-2.5 border-b border-b-neutral align-middle"
        }
      >
        <input
          type="checkbox"
          onChange={() => selectMessage(id)}
          checked={isActive}
          aria-label="Select message"
          className={"!w-6 !h-6"}
        />
      </div>
      <div className="messageRowMemberImage member-image table-cell py-3 px-1.5 border-b border-b-neutral align-middle">
        {members.length == 1 && member.html_url && member.name ? (
          <a className="member-link" href={member.html_url}>
            <img
              src={find(member.images, { width: 80, height: 80 })?.url}
              alt={member.name}
              title={member.name}
              className={"w-10 h-10 max-w-none"}
            />
          </a>
        ) : (
          <i className="fa fa-users text-gray-400 w-10 h-10 block fa-xl flex items-center justify-center" />
        )}
      </div>
      <div
        className="messageRowDetails table-cell w-3/12 py-3 px-1.5 border-b border-b-neutral align-middle"
        role="button"
        tabIndex={0}
        onKeyDown={(e) => [" ", "Enter"].includes(e.key) && navigateDetails()}
        onClick={navigateDetails}
      >
        <div className="messageRowInfo">
          <div className="messageRowDateMember">
            <div className="messageRowMemberName">
              {member.html_url ? (
                <a
                  onClick={(e) => e.stopPropagation()}
                  href={member.html_url}
                  title={member.name}
                  className={classNames({ "font-semibold": !read })}
                >
                  {member.name ||
                    Preload.current_network.config.anonymize_membership_name}
                </a>
              ) : (
                <span className={classNames({ "font-semibold": !read })}>
                  {member.name ||
                    Preload.current_network.config.anonymize_membership_name}
                </span>
              )}
              {to_count && to_count > 1 ? (
                <span
                  className={"text-muted text-sm"}
                  title={join(
                    map(members, (m) => m.name),
                    ", ",
                  )}
                >{` +${to_count - 1}`}</span>
              ) : null}
            </div>
            <div className="messageRowMeta text-sm">
              <span className={"text-muted"}>
                {moment(date).format("DD.MM.YYYY HH:mm")}
              </span>
              {conversationLength ? <span> ({conversationLength})</span> : null}
              {hasReplies ? (
                <FontAwesomeIcon
                  icon={solid("reply")}
                  className={"text-muted ml-1"}
                  title={I18n.t("js.messages.message_list.replied_icon_title")}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "messageRowContent table-cell w-9/12 py-3 px-1.5 border-b border-b-neutral",
          {
            "text-gray-400": read,
          },
        )}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => [" ", "Enter"].includes(e.key) && navigateDetails()}
        onClick={navigateDetails}
      >
        {subject ? (
          <p className={"font-semibold"}>{subject}</p>
        ) : (
          <p>{I18n.t("js.messages.composer.no_subject")}</p>
        )}
        <p>{content}</p>
      </div>
      <div
        className={
          "table-cell py-3 px-1.5 pr-3 border-b border-b-neutral align-middle"
        }
      >
        {hasAttachments ? (
          <i className="fa-light fa-paperclip-vertical fa-xl text-gray-400" />
        ) : null}
      </div>
    </div>
  );

  function navigateDetails() {
    navigate(`/messages/${id}`, {
      state: {
        pathname: location.pathname,
        search: location.search,
      },
    });
  }
}

export default MessageRow;
